import React, { useState, useEffect } from "react";
import fasterDevelopment from "../../images/fasterDevelopment.png";
import endUser from "../../images/endUser.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const IdealUseCases = (icons) => {
  const { isMobileView } = icons;
  return (
    <>
      {icons.usecases.id === "terra" ? (
        <div
          className="partnerSecTechnologiesTerraform serviceHotspot"
          id="idealUseCases"
        >
          <div className="partSecwrapTechnologies">
            <div className="partsecleftTechnologies partseccover">
              <div className="problem-technologies serviceHotspot text-white">
                <div className="overviewTitle open-sans">
                  {icons.usecases.title}
                </div>
                <div className="overviewHeading mx-0 text-center text-white roboto">
                  {icons.usecases.titleheading}
                </div>
                <div className="overviewDescriptionSection ">
                  <div
                    className="overviewDescriptionTechnologies text-white mb-5"
                    dangerouslySetInnerHTML={{ __html: icons.usecases.des }}
                  />
                </div>

                <Slider className="partner-slider">
                  {icons.usecases.challenges.map(({ title, des }, index) => (
                    <div key={index} className="partsecleft">
                      <div className="leftSection d-md-flex flex-md-column align-items-md-center w-100">
                        <div className="secHeading text-center text-white roboto">
                          {title}
                        </div>
                        <p
                          className="text-center"
                          dangerouslySetInnerHTML={{ __html: des }}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={` ${
              isMobileView
                ? "ourApproachTechnologies serviceHotspot mt-0"
                : "ourApproachTechnologies serviceHotspot"
            }`}
            id="idealUseCases"
          >
            <div className="global-margin">
              <div
                className={` ${
                  isMobileView
                    ? "approchTitle text-start  mb-0 text-center"
                    : "approchTitle text-start  mb-0"
                }`}
              >
                {icons.usecases.title}
              </div>
              <div
                className={` ${
                  isMobileView
                    ? "approchHeading font-size-24 mb-0 text-start roboto capitalize py-2 text-center"
                    : "approchHeading mb-0 text-start roboto capitalize py-5"
                }`}
              >
                {icons.usecases.heading}
              </div>
              <div
                className={` ${
                  isMobileView
                    ? "approchdescription text-start  capitalize text-center"
                    : "approchdescription text-start  capitalize pb-5"
                }`}
                dangerouslySetInnerHTML={{ __html: icons.usecases.des }}
              />
            </div>
          </div>
          <div className="process serviceHotspot pb-0">
            <div className="global-margin">
              <div className="prorcss_wrap w-100">
                <div className="ourApproachCases w-100 serviceHotspot pt-0 mb-0">
                  {/* <div className="containerwrap"> */}
                  <div
                    className={` ${
                      isMobileView
                        ? "approchHeader text-start roboto capitalize m-0"
                        : "approchHeader text-start roboto capitalize pb-5"
                    }`}
                  >
                    {icons.usecases.bulletHeading}
                  </div>
                  {/* </div> */}
                </div>
                <div className="d-flex ">
                  <div className="processRightSectionServices w-100">
                    <div className="inner_process d-md-flex justify-content-md-center w-100">
                      <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                        {icons.usecases.bulletPoints &&
                          icons.usecases.bulletPoints.map(
                            (bulletPoint, index) => (
                              <div
                                className={`processDetails col-md-4 col-12 ${
                                  index === 3 || index === 7
                                    ? "me-md-0 pe-md-0"
                                    : ""
                                }`}
                                key={index}
                              >
                                <div className="number">
                                  {icons.icons?.length > 0 ? (
                                    <img
                                      src={icons.icons[index].url}
                                      alt={bulletPoint}
                                      className="mb-2"
                                      width={`${
                                        index === 11 ? "85px" : "60px"
                                      }`}
                                      height="50px"
                                      loading="lazy"
                                    />
                                  ) : (
                                    <>
                                      {index < 9
                                        ? `0${index + 1}`
                                        : `${index + 1}`}
                                    </>
                                  )}
                                </div>
                                <div className="process_content_docker  text-light">
                                  {bulletPoint}
                                </div>
                              </div>
                            )
                          )}
                        {icons.usecases.bulletPointsWithDesc &&
                          icons.usecases.bulletPointsWithDesc.map(
                            (bulletPoint, index) => (
                              <div
                                className="processDetailsDesc col-md-12 col-12"
                                key={index}
                              >
                                <div className="number">
                                  <>
                                    {index < 9
                                      ? `0${index + 1}`
                                      : `${index + 1}`}
                                  </>
                                </div>
                                <div className="process_content_title mb-3 open-sans">
                                  {bulletPoint.title}
                                </div>
                                <div className="process_content_solution">
                                  {bulletPoint.des}
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
