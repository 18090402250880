import React from "react";

export const ManagedCloudServices = (managedCloudServices) => {
  const { isMobileView } = managedCloudServices;

  return (
    <>
      <div
        className="ourApproachServices serviceHotspot pt-5"
        id="managedCloudServices"
      >
        <div className="global-margin">
          <div
            className={` ${
              isMobileView
                ? "approchTitle  text-center"
                : "approchTitle  text-start"
            }`}
          >
            {managedCloudServices.managedCloudServices.title}
          </div>
          <div
            className={` ${
              isMobileView
                ? "approchHeading text-center capitalize roboto"
                : "approchHeading text-start capitalize roboto"
            }`}
            dangerouslySetInnerHTML={{
              __html: managedCloudServices.managedCloudServices.heading,
            }}
          />

          <div
            className={` ${
              isMobileView
                ? "approchdescription  text-center capitalize"
                : "approchdescription  text-start capitalize"
            }`}
          >
            {managedCloudServices.managedCloudServices.des1}
          </div>
          <div className="approchdescription pt-3 open-sans text-start">
            {managedCloudServices.managedCloudServices.des2}
          </div>
        </div>
      </div>
      <div className="process serviceHotspot pb-0">
        <div className="global-margin d-flex justify-content-center">
          <div className="prorcss_wrap d-md-flex ">
            <div
              className={` ${
                isMobileView
                  ? "processRightSectionServices w-100 m-0"
                  : "processRightSectionServices w-100"
              }`}
            >
              <div className="inner_process d-md-flex justify-content-center w-100">
                <div className="inner_process_left d-md-flex w-100 col-md-12 col-12 flex-md-wrap">
                  {managedCloudServices.managedCloudServices.bulletPoints.map(
                    (bulletPoint, index) => (
                      <div
                        className={`processDetails ${
                          managedCloudServices.managedCloudServices.id ===
                            "benefits" &&
                          managedCloudServices.managedCloudServices.id ===
                            "cloudServices"
                            ? "col-md-w-3"
                            : "col-md-4"
                        } ${
                          managedCloudServices.managedCloudServices.id ===
                          "otherServices"
                            ? "me-md-0 pe-md-0"
                            : ""
                        } col-12`}
                        key={index}
                      >
                        <div className="number">
                          {managedCloudServices.icons?.length > 0 ? (
                            <>
                              <img
                                src={managedCloudServices.icons[index].url}
                                alt={bulletPoint}
                                className="mb-2"
                                width="60px"
                                height="60px"
                              />
                            </>
                          ) : (
                            <>
                              {index < 9 ? `0${index + 1}.` : `${index + 1}.`}
                            </>
                          )}
                        </div>

                        <div
                          className="process_content_docker  capitalize"
                          dangerouslySetInnerHTML={{ __html: bulletPoint }}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourApproachOfferings serviceHotspot mt-0 py-md-0">
          <div className="global-margin">
            <div className="approchdescription pb-md-5  text-start capitalize">
              {managedCloudServices.managedCloudServices.footnote}
            </div>
          </div>
        </div>
        {managedCloudServices.managedCloudServices.highlights1 &&
          managedCloudServices.managedCloudServices.id ===
            "ourCloudExpertise" && (
            <div className="containerwrap d-flex justify-content-center w-100 mt-5">
              <div className="prorcss_wrap d-md-flex justify-content-md-center  col-md-12 col-12 w-100 ">
                <div className="processRightSectionSolutions col-md-12 col-12 w-100">
                  <div className="inner_process d-md-flex justify-content-md-center w-100 mt-3 mt-md-0 mt-lg-0 col-md-12 col-12">
                    <div className="inner_process_left d-md-flex justify-content-md-center w-100 col-md-12 col-12">
                      {managedCloudServices.managedCloudServices.highlights1.map(
                        (highlight, index) => (
                          <div
                            className="d-md-flex flex-md-column align-items-center col-md-4 col-12"
                            key={index}
                          >
                            <div className="processDetails">
                              <div className="number">
                                <div className="d-md-flex justify-content-md-center d-flex justify-content-center">
                                  {index === 0 && (
                                    <img
                                      src={managedCloudServices.icons1[3].url}
                                      alt="aws logo"
                                      loading="lazy"
                                    />
                                  )}
                                  {index === 1 && (
                                    <img
                                      src={managedCloudServices.icons1[1].url}
                                      alt="azure logo"
                                      loading="lazy"
                                    />
                                  )}
                                  {index === 2 && (
                                    <img
                                      src={managedCloudServices.icons1[2].url}
                                      alt="gcp logo"
                                      loading="lazy"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="process_content_solution_purple text-center open-sans">
                                {highlight}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
